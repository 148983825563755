import React from "react"

import Layout from "../components/layout"
import SEO from "../components/atoms/SEO"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="uk-container uk-text-center">
      <div className="uk-padding">
        <h1 className="purple">404: Not Found</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
